import permessi from "@/config/permessi";
import ElencoAdesioniPerPagamentoItem from "@/models/registrazionePagamenti/elencoAdesioniPerPagamentoItem";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.MY_OVERINS.ELENCO_PAGAMENTI;

@Component
export default class TuoiPagamentiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.WEB.MyWebins.PAGAMENTI];
	}

	nomeMesi: string[] = [
		"generico.gennaio",
		"generico.febbraio",
		"generico.marzo",
		"generico.aprile",
		"generico.maggio",
		"generico.giugno",
		"generico.luglio",
		"generico.agosto",
		"generico.settembre",
		"generico.ottobre",
		"generico.novembre",
		"generico.dicembre"
	];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ItemID", width: 80, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, hidden: true },

			{
				field: "mese",
				title: "Mese",
				width: 90,
				renderFunction: this.renderCellMesi,
				type: "renderfunction",
				filterable: true,
				groupable: true,
				filterType: "numeric-int",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "numeroAdesione",
				title: "N. Adesione",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: 150,
				filterable: true,
				filterType: "numeric-int",
				editDisabled: true,
				export: true
			},
			{
				field: "destinazione",
				title: "Destinazione",
				export: true,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				editHide: true
			},
			{
				field: "inizioViaggio",
				title: "InizioViaggio",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "renderfunction",
				renderFunction: this.renderFunctionNoTimeZoneDate,
				headerType: "daterange",
				editHide: true
			},
			{
				field: "numeroPartecipanti",
				title: "Numero Partecipanti",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: 100,
				filterable: true,
				filterType: "numeric-int",
				export: true,
				format: "{0:n0}"
			},

			{
				field: "riferimentoPratica",
				title: "RiferimentoPratica",
				width: 120,
				type: "renderfunction",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "totalePremio",
				title: "Totale Premio",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editDisabled: true,
				export: true
			},
			{
				field: "totalePremioDaPagare",
				title: "Da Pagare",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editDisabled: true,
				export: true
			},
			{
				field: "importoSconto",
				title: "Sconto",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "importoPagato",
				title: "Pagato",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "dataPagamento",
				title: "DataPagamento",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange"
			},
			{
				field: "saldo",
				title: "Saldo",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true,
				editDisabled: true
			},

			{
				field: "_ck_select",
				title: " ",
				width: 100,
				cell: "defaultCellTemplate",
				type: "ck_select",
				sortable: false,
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				headerType: "boolean"
			}

		];
	}

	get columnsMobile(): Array<any> {
		return [
			{ field: "itemID", filterable: false, title: "ItemID", width: 80, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, hidden: true },
			
			{
				field: "_ck_select",
				title: " ",
				width: 100,
				cell: "defaultCellTemplate",
				type: "ck_select",
				sortable: false,
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				headerType: "boolean"
			},
			{
				field: "mese",
				title: "Mese",
				width: 90,
				renderFunction: this.renderCellMesi,
				type: "renderfunction",
				filterable: true,
				groupable: true,
				filterType: "numeric-int",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "numeroAdesione",
				title: "N. Adesione",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: 80,
				filterable: true,
				filterType: "numeric-int",
				editDisabled: true,
				export: true
			},
			{
				field: "destinazione",
				title: "Destinazione",
				export: true,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				editHide: true,
				width: 200
			},
			{
				field: "inizioViaggio",
				title: "InizioViaggio",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "renderfunction",
				renderFunction: this.renderFunctionNoTimeZoneDate,
				headerType: "daterange",
				editHide: true
			},
			{
				field: "numeroPartecipanti",
				title: "Numero Partecipanti",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: 100,
				filterable: true,
				filterType: "numeric-int",
				export: true,
				format: "{0:n0}"
			},

			{
				field: "riferimentoPratica",
				title: "RiferimentoPratica",
				width: 120,
				type: "renderfunction",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "totalePremio",
				title: "Totale Premio",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editDisabled: true,
				export: true
			},
			{
				field: "totalePremioDaPagare",
				title: "Da Pagare",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editDisabled: true,
				export: true
			},
			{
				field: "importoSconto",
				title: "Sconto",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "importoPagato",
				title: "Pagato",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "dataPagamento",
				title: "DataPagamento",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange"
			},
			{
				field: "saldo",
				title: "Saldo",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true,
				editDisabled: true
			},
		];
	}

	created() {
		this.getData();

		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}
	}

	mounted() {}

	exportExcel() {
		this.exportGridExcel(_ENDPONT, null, null, "Registrazione Pagamenti");
	}

	getData() {
		this.getGridData(_ENDPONT);
	}

	renderCellMesi(data: number) {
		var result = "";
		if (data) result = `${this.$i18n.t(this.nomeMesi[data - 1])}`;

		return result;
	}

	eseguiPagamento() {
		var self = this;

		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let elementToDoAction: number[] = (this.gridData.data as ElencoAdesioniPerPagamentoItem[])
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});

			if (elementToDoAction && elementToDoAction.length > 0) {
				let message = self.$createElement("div", { domProps: { innerHTML: `${self.$i18n.t("messaggio.confirmExecutePayment")}` } });
				let title = this.$i18n.t("titolo.eseguiPagamento").toString();

				self.$bvModal
					.msgBoxConfirm([message], {
						title: title,
						okVariant: "warning",
						okTitle: self.$i18n
							.t("btn.si")
							.toString()
							.toUpperCase(),
						cancelTitle: self.$i18n
							.t("btn.no")
							.toString()
							.toUpperCase(),
						cancelVariant: "outline-theme",
						footerClass: "",
						headerBgVariant: "warning"
					})
					.then(value => {
						if (value) {
							api.eseguiPagamento(elementToDoAction)
								.then(res => {
									this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
										variant: "success",
										title: this.$i18n.t("generico.azioneeseguita").toString(),
										solid: true
									});

									window.location.href = res.data;
								})
								.catch(err => {
									console.log(err);
								});
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.noElementForPayment").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
